import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { Box, Button, Grid } from '@material-ui/core';
import { getImage } from 'gatsby-plugin-image';

import Layout from '../components/layout'
import CardLink from '../components/CardLink/cardLink';
import Hero from '../components/Hero';
import Subtitle from '../components/Subtitle';

import { addProtocol } from '../utils/format';
import useStyles from '../pagesStyles/actualitesStyles';

const News = ({ data }) => {
  const classes = useStyles();

  const articles = data.articles.edges;
  const ARTICLES_COUNT = data.articles.totalCount;
  const CHUNKS_PER_PAGE = process.env.GATSBY_ARTICLES_PER_PAGE;
  const md = data.markdownRemark.frontmatter;

  const [page, setPage] = useState(1);
  const [allLoaded, setAllLoaded] = useState(CHUNKS_PER_PAGE >= ARTICLES_COUNT);

  const onLoad = () => {
    setPage(page + 1);
    setAllLoaded((page + 1) * CHUNKS_PER_PAGE >= ARTICLES_COUNT);
  }

  const renderArticles = () => {
    const paginated = Array.from(articles).splice(0, page * CHUNKS_PER_PAGE);

    return paginated.map((article, key) => {
      const articleInfos = article?.node;
      const articleImage = getImage(articleInfos.image?.gatsbyImageData);
      // If the article's image was not set, use the same image as the first image found in the article's content
      const imageFromContent = getImage(articleInfos?.article?.references.find(ref => ref?.gatsbyImageData)?.gatsbyImageData);
      const image = articleImage || imageFromContent;

      return <CardLink
        key={key}
        title={articleInfos.titre}
        description={articleInfos.description.description}
        image={image}
        link={articleInfos.adherent ? `${addProtocol(process.env.GATSBY_ADHERENT_URL, "https")}/news/${articleInfos.id}` : `/actualites/${articleInfos.slug}`}
        isOutsideLink={articleInfos.adherent ?? false}
      />
    })
  }

  return (
    <Layout>
      <Helmet>
        <html lang="fr" />
        <title>{md.SEOtitle}</title>
        <meta name="description" content={md.SEOdescription} />
      </Helmet>

      <Hero
        image={md.coverImage.childImageSharp.gatsbyImageData}
        title={md.title}
        alt={md.alt}
      />
      <Subtitle text={md.paragraph} />


      <Grid container className={classes.container}>
        {renderArticles()}
      </Grid>


      {/* Load More button */}
      {!allLoaded && (
        <Grid container justify="center">
          <Box my={4}>
            <Button
              type={'button'}
              onClick={() => onLoad()}
              disabled={allLoaded}
              className={classes.button}
            >
              Voir plus d'articles
            </Button>
          </Box>
        </Grid>
      )}
    </Layout >
  )
}

export default News

export const query = graphql`
  query {
    articles: allContentfulArticles(sort: { fields: createdAt, order: DESC }) {
      totalCount
      edges {
        node {
          id
          adherent
          slug
          createdAt(formatString: "D/M/YYYY")
          titre
          description {
            description
          }
          image {
            gatsbyImageData(height: 200, width:300, placeholder: BLURRED)
          }
          article {
            raw
            references {
              gatsbyImageData(height: 200, width:300, placeholder: BLURRED)
            }
          }
        }
      }
    },
    markdownRemark(fileAbsolutePath: {regex: "/actualités.md/"}) {
        frontmatter {
            SEOtitle
            SEOdescription
            title
            alt
            paragraph
            coverImage {
                childImageSharp {
                    gatsbyImageData
                    }
                }
            }
      }
  }
`